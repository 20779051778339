import { timeout } from "./util";
import { notify } from "./notification";

enum StoreTokenKeys {
  Auth = "AUTH_TOKEN",
  Renew = "AUTH_RENEW",
}

const sessionPath = "session";
const store = window.localStorage;

let authInProgress = false;

export const getAuthStoreKeys = (): string[] => [
  StoreTokenKeys.Auth,
  StoreTokenKeys.Renew,
];

export const setAuthHeaders = (request) => {
  const token = store.getItem(StoreTokenKeys.Auth);

  if (token) {
    request.headers.set("Authorization", token);
  }
};

export const isAuthenticated = (): boolean =>
  !!store.getItem(StoreTokenKeys.Auth);

export const login = (api: any) => async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<boolean> => {
  try {
    const response = await api
      .post(sessionPath, {
        json: { user: { email, password } },
      })
      .json();
    const { token, renew_token } = response.data;

    store.setItem(StoreTokenKeys.Auth, token);
    store.setItem(StoreTokenKeys.Renew, renew_token);

    return true;
  } catch (error) {
    store.removeItem(StoreTokenKeys.Auth);
    store.removeItem(StoreTokenKeys.Renew);

    throw error;
  }
};

export const maybeRenew = (api: any) => async (): Promise<boolean> => {
  // TODO rethink this, maybe add subscriber to resolve later
  while (authInProgress) {
    await timeout(undefined, 1000);

    if (!authInProgress) {
      return isAuthenticated();
    }
  }

  if (!store.getItem(StoreTokenKeys.Renew)) {
    return false;
  }

  try {
    authInProgress = true;

    const options = {
      headers: { Authorization: store.getItem(StoreTokenKeys.Renew) },
    };
    store.removeItem(StoreTokenKeys.Renew);
    const response = await api.post(`${sessionPath}/renew`, options).json();
    const { token, renew_token } = response.data;

    store.setItem(StoreTokenKeys.Auth, token);
    store.setItem(StoreTokenKeys.Renew, renew_token);

    authInProgress = false;

    return true;
  } catch (error) {
    console.error("renew auth went wrong");
    authInProgress = false;
    notify("Graag opnieuw inloggen.");

    store.clear();
    // force login screen
    window.location.reload();
  }
};

export const logout = (api: any) => async (): Promise<boolean> => {
  try {
    api.delete(sessionPath);
  } finally {
    store.clear();
    return true;
  }
};

export const autoRenewal = (api: any): (() => void) => {
  const intervalID = window.setInterval(() => {
    maybeRenew(api)();
  }, 3 * 60 * 1000); // 3 minutes

  const cleanup = () => {
    window.clearInterval(intervalID);
  };

  return cleanup;
};
