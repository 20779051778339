import React from "react";
// import React, { useEffect, useState } from "react";
// import { motion } from "framer-motion";
// import { Loader } from "react-feather";

import { useScrollToTop } from "lib/util";
// import { get, Ranking } from "lib/api";
// import { PullToReload } from "lib/pull-to-reload";
// import { useOnVisible } from "lib/useOnVisible";

import styles from "./index.module.scss";

// let requestCancel;

// const fetchRankings = async (mergeState) => {
//   const { abortController, request } = get("rankings");
//   requestCancel = abortController;

//   request.then((response) => {
//     if (!response) {
//       return; // request cancelled
//     }

//     window.localStorage.setItem("rankings", JSON.stringify(response));
//     mergeState({ rankings: response });
//   });
// };

export const RankingsView: React.FunctionComponent = () => {
  // const initialRankings = JSON.parse(
  //   window.localStorage.getItem("rankings")
  // ) || [null];

  // const [state, setState] = useState<{ rankings: Ranking[] }>({
  //   rankings: initialRankings,
  // });
  // const mergeState = (newState) =>
  //   setState((prevState) => ({ ...prevState, ...newState }));

  // useOnVisible((isVisible) => {
  //   if (isVisible) {
  //     fetchRankings(mergeState);
  //   }
  // });

  // useEffect(() => {
  //   fetchRankings(mergeState);

  //   return () => {
  //     requestCancel.abort();
  //   };
  // }, []);

  useScrollToTop();

  // return (
  //   <PullToReload
  //     onReload={async () => {
  //       await fetchRankings(mergeState);
  //     }}
  //     labels={{ reload: "↓", reloading: "laden…", done: "↑" }}
  //   >
  //     <div className={styles.container}>
  //       {state.rankings.map((ranking, idx) => {
  //         if (ranking) {
  //           return (
  //             <RankingView
  //               key={ranking.id}
  //               position={idx + 1}
  //               ranking={ranking}
  //             />
  //           );
  //         } else {
  //           return <RankingPlaceholderView key={idx} />;
  //         }
  //       })}
  //     </div>
  //   </PullToReload>
  // );
  return (
    <div className={styles.container}>
      <p>Tijdelijk niet beschikbaar.</p>
    </div>
  );
};

// const spring = {
//   type: "spring",
//   damping: 20,
//   stiffness: 100,
//   delay: 1,
// };

// const RankingView: React.FunctionComponent<{
//   position: number;
//   ranking: Ranking;
// }> = ({ position, ranking }) => (
//   <motion.div
//     className={styles.ranking}
//     initial={{ opacity: 0 }}
//     animate={{ opacity: 1 }}
//     transition={{ duration: 0.2 }}
//     layoutTransition={spring}
//   >
//     <div className={styles.position}>{position}.</div>
//     <div className={styles.name}>{ranking.name}</div>
//     <div className={styles.skillRating}>
//       SR {Math.round(ranking.skill_rating)}
//     </div>
//   </motion.div>
// );

// const RankingPlaceholderView: React.FunctionComponent = () => (
//   <div className={styles.ranking} style={{ background: "none" }}>
//     <motion.div
//       animate={{ rotate: 360 }}
//       transition={{
//         loop: Infinity,
//         ease: "linear",
//         duration: 2,
//       }}
//       style={{ height: "24px", opacity: 0.5 }}
//     >
//       <Loader />
//     </motion.div>
//   </div>
// );
