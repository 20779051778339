import ky from "ky";

import { unregister as unregisterServiceWorker } from "service-worker/registration";
import { Observer } from "./Observer";

import {
  login as sessionLogin,
  logout as sessionLogout,
  maybeRenew as sessionRenew,
  autoRenewal,
  setAuthHeaders
} from "lib/session";

export type ApiResponse = {
  data: any;
};

export type Player = {
  id: number;
  name: string;
  email: string;
};

export type MatchPlayer = {
  id: number;
  name: string;
};

export type Ranking = {
  id: number;
  name: string;
  mu: number;
  sigma: number;
  total_matches: number;
  skill_rating: number;
};

export type Match = {
  id: number;
  inserted_at: string;
  team_one_final_score: number;
  team_one_half_time_score: number;
  team_one_half_time_switch: number;
  team_one_player_back: MatchPlayer;
  team_one_player_front: MatchPlayer;
  team_two_final_score: number;
  team_two_half_time_score: number;
  team_two_half_time_switch: boolean;
  team_two_player_back: MatchPlayer;
  team_two_player_front: MatchPlayer;
};

export const connectionManager = new Observer();

let connections = 0;

const incConnections = () => {
  connections++;
  if (connections === 1) {
    connectionManager.notify(true);
  }
};

const decConnections = () => {
  connections--;
  if (connections === 0) {
    connectionManager.notify(false);
  }
};

const origin = process.env.REACT_APP_API_ORIGIN;
const baseURL = `${origin}/api/v1/`;

const apiUnauthorized = ky.create({
  prefixUrl: baseURL
});

// api requests with authorization
const api = apiUnauthorized.extend({
  retry: {
    limit: 2,
    methods: ["get", "put", "head", "delete", "options", "trace"],
    statusCodes: [401, 408, 413, 429, 500, 502, 503, 504]
  },
  hooks: {
    beforeRequest: [setAuthHeaders],
    beforeRetry: [
      async ({ request, error }) => {
        if (isUnauthorizedError(error)) {
          await sessionRenew(apiUnauthorized)();
          setAuthHeaders(request);
        }
      }
    ]
  }
});

const isUnauthorizedError = error => error?.response?.status === 401;

const apiRequest = (method: string) => (
  resource: string,
  args?: any
): {
  abortController: AbortController;
  request: Promise<any>;
} => {
  incConnections();

  const abortController = new AbortController();
  const request = api(resource, {
    method,
    signal: abortController.signal,
    json: args
  })
    .json()
    .then((response: ApiResponse) => {
      return response.data;
    })
    .finally(decConnections);

  return { abortController, request };
};

export const get = apiRequest("GET");
export const post = apiRequest("POST");

export const login = async ({
  email,
  password
}: {
  email: string;
  password: string;
}): Promise<boolean> => {
  return await sessionLogin(apiUnauthorized)({ email, password });
};

export const logout = async (): Promise<boolean> => {
  try {
    await unregisterServiceWorker();
    return await sessionLogout(apiUnauthorized)();
  } finally {
    window.location.reload();
  }
};

export const sessionAutoRenewal = (): (() => void) => {
  return autoRenewal(api);
};

export const renewLogin = async (): Promise<boolean> => {
  return await sessionRenew(apiUnauthorized)();
};
